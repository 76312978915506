import { Link } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ItemGridStyles = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 1rem;
  grid-auto-rows: auto, 300px;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const ItemStyles = styled.div`
  display: grid;
  padding-bottom: 2rem;
  /* Take your row sizing not from the ItemStyles div, but from the  ItemGridStyles grid */
  @supports not (grid-template-rows: subgrid) {
    --rows: auto, 325px;
  }
  width: 300px;
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 2;
  p {
    margin: 0;
  }
`;

const TitleStyles = styled.div`
  font-size: 1.75rem;
  padding-bottom: 1rem;
`;

function SingleItem({ item }) {
  return (
    <ItemStyles>
      <Link to={`/item/${item.slug.current}`}>
        <TitleStyles>
          <p>{item.name}</p>
        </TitleStyles>
        <Img fluid={item.image?.asset?.fluid} alt={item.name} />
      </Link>
    </ItemStyles>
  );
}

export default function ItemList({ items }) {
  return (
    <ItemGridStyles>
      {items.map((item) => (
        <SingleItem key={item.id} item={item} />
      ))}
    </ItemGridStyles>
  );
}
