import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const CategoryStyles = styled.div`
  font-family: SortsMillGoudyFont-Regular;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 0.5rem;
  margin-bottom: 4rem;
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding: 5px;
    background: var(--button);
    border-radius: 2px;
    text-decoration: none;
    font-size: 2rem;
    .count {
      background: white;
      padding: 2px 5px;
    }
    &[aria-current='page'] {
      background: var(--grey);
    }
  }
`;

const FullCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 4rem;
`;

function countItemsInCategories(items) {
  // Return the items with counts
  const counts = items
    .map((item) => item.categories)
    .flat()
    .reduce((acc, category) => {
      // check if this is an existing Category
      const existingCategory = acc[category.id];
      if (existingCategory) {
        //  if it is, increment by 1
        existingCategory.count += 1;
      } else {
        // otherwise create a new entry in our acc and set it to one
        acc[category.id] = {
          id: category.id,
          name: category.name,
          count: 1,
        };
      }
      return acc;
    }, {});
  // sort them based on their count
  const sortedCategories = Object.values(counts).sort(
    (a, b) => b.count - a.count
  );
  return sortedCategories;
}

export default function CategoriesFilter({ activeCategory }) {
  const { items } = useStaticQuery(graphql`
    query {
      items: allSanityItem(filter: { public: { eq: true } }) {
        nodes {
          categories {
            name
            id
          }
        }
      }
    }
  `);

  const categoriesWithCounts = countItemsInCategories(items.nodes);

  return (
    <>
      <CategoryStyles>
        <Link to="/shop">
          <span className="name">View All</span>
        </Link>
        {categoriesWithCounts.map((category) => (
          <Link
            to={`/category/${category.name}`}
            key={category.id}
            className={category.name === activeCategory ? 'active' : ''}
          >
            <span className="name">{category.name}</span>
          </Link>
        ))}
      </CategoryStyles>
      {!activeCategory ? <FullCategory>FULL COLLECTION</FullCategory> : ''}
    </>
  );
}
